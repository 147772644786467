import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { AboutPageComponent } from './about-page/about-page.component';
import { InscriptionPageComponent } from './inscription-page/inscription-page.component';
import { ConnexionPageComponent } from './connexion-page/connexion-page.component';
import { AvailableGroups } from './available-groups/available-groups.component';
import { authguardGuard } from './service/authguard.guard';
import { CoursComponent } from './cours/cours.component';


const routes: Routes = [
  {path:'', component:ConnexionPageComponent},
  {path:'About', component:AboutPageComponent},
  {path:'Inscription',component:InscriptionPageComponent},
  {path:'Connexion',component:ConnexionPageComponent},
  {path:'Classe',component:AvailableGroups,canActivate: [authguardGuard]},
  {path:'Cours',component:CoursComponent,canActivate: [authguardGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
