import { Quizz } from "./quizz.model";
import { quizzSlide } from "./quizzSlide.model";
import { Stroke } from "./stroke.model";

export class Cours {
    constructor(
                // information du cours
                public title?: string,
                public auteur?: string,
                public Id?: string ,

                public List_ID_slide:string[]=[],
                public List_version_slide:string[]=[],

                // //information quizz
                // public UIDQuizz?:string,
                // public AnswerUID?:any,
                // public quizz_list:Quizz[]=[],
                public  response_list:Quizz[]=[],
                public response_Strokes:Stroke[]=[],
                // public mode?:string, 

                //information affichage/ slide
                public idImage:string="",
                public idslide:string="",
                public idSlideProf:string="",
                public imageSlide= new Image(),
                public Strokes:Stroke[]=[],

                public responseActive:boolean=false,
                

                public quizz_Slide_list:quizzSlide[]=[]
                
                
                ) {
    }
  }