import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StudentService } from '../service/student.service';

@Component({
  selector: 'app-available-groups',
  templateUrl: './available-groups.component.html',
  styleUrls: ['./available-groups.component.scss']
})


export class AvailableGroups {

  constructor(public StudentService: StudentService, private router: Router ){}

}
