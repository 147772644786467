<main>
    <div class="container">
        <div class="pt-5 text-white">
            <header class="py-5 mt-5">
                <h1 class="display-4">À propos</h1>
            </header>
            <h2 >Projet Intuinote</h2>
            <div class="py-5">
                <p class="lead">IntuiNote est un nouvel environnement numérique d'apprentissage actif pour l'enseignement synchrone
                    (présentiel et distanciel). </p>
                <p class="lead">Il a pour objectif d’optimiser les possibilités d’interactions entre enseignant et
                    apprenants. Il repose notamment sur la prise de notes manuscrites et des QCM graphiques élaborés à la
                    volée sur un support tablette tactile/stylet.</p>
            </div>
            <h2 >Contact</h2>
            <div class="py-5">

                <p class="lead">Eric Anquetil, Professeur, INSA Rennes,
                    laboratoire de l’IRISA, Responsable équipe
                    IntuiDoc.</p>
                                       
                <p class="lead">Nathalie Girard, MC, Université de Rennes,
                    laboratoire de l’IRISA/IntuiDoc</p>

                <p class="lead">Bruno Hortollary, Ingénieur INSA Rennes,
                    laboratoire de l’IRISA/IntuiDoc</p>
            </div>
        </div>
    </div>
</main>