import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment } from 'src/environments/environment';
// import { Socket } from 'ngx-socket-io';
const io = require("socket.io-client/dist/socket.io") ;
import {Socket} from 'socket.io';
import { response } from 'express';


export class WebSocketService {

 
//---------------------try with sngx socket io ------------------------------

  // constructor(private socket: socketIoClient.Socket) { }


  // emitInfo(eventName: string, data: any ){
  //   console.log('emit: '+ eventName);
  //   this.socket.emit(eventName,data);
  // }

  // getInfo(eventName: string): Observable<string> {
  //   return this.socket.fromEvent(eventName);
  // }

  //---------------------try with socket io client ------------------------------
  socket!: Socket;
  _isConnect:boolean = false;


  constructor(){}

  tryconnect() {
    this.socket = io(Environment.SOCKET_ENDPOINT,{forceNew: true, rejectUnauthorized: false});
    console.log('Try to connect')
  }

  listen(eventName: string) {
    return new Observable((observer) => {
      this.socket.on(eventName,(data: any) => { 
        observer.next(data);
      });
    });
  }

  emit(eventName: string ,data: any) {
    this.socket.emit(eventName,data);
  }

  emitWithCallback(eventName: string ,data: any){
    this.socket.emit(eventName,data,function(res:any){
      console.log(res)
      return res; 
    });
  }
  
  authenticated(){
    console.log("guard", this._isConnect)
    if(this._isConnect)
      return true
    else
      return false
  }
  

}
