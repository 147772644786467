import { Quizz } from "./quizz.model";
export class quizzSlide {
    constructor(

        public UIDQuizz:string,
        public AnswerUID:any,
        public mode:string,
        public sumited:boolean,
        public quizz_list:Quizz[]=[],
        public resStroke:number[][]=[],


    ) {
    }
  }