import { AfterContentInit, AfterViewInit, Component,  ElementRef, HostListener, OnInit, ViewChild, ViewChildren, getModuleFactory } from '@angular/core';
import { StudentService } from '../service/student.service';
import { analyze } from 'eslint-scope';
import { Subscription, isEmpty } from 'rxjs';
import { Stroke } from '../models/stroke.model';
import { Handler } from 'express';
import { Quizz } from '../models/quizz.model';
import { quizzSlide } from '../models/quizzSlide.model';
import { responseStroke } from '../models/responseStroke.model';


@Component({
  selector: 'app-canvas-slide',
  templateUrl: './canvas-slide.component.html',
  styleUrls: ['./canvas-slide.component.scss']
})
export class CanvasSlideComponent implements AfterContentInit, OnInit{

  imageToAdd = require("file-loader!./../../assets/Image1.png");
  constructor(public StudentService: StudentService,){}
  private subscription1!: Subscription;
  private subscription2!: Subscription;
   //@ViewChild('myCanvas') public canvas?: ElementRef;
  private oldImage  = new Image()
  private oldwith:any;
  private oldheight:any;

  private clickX: number[] = [];
  private clickY: number[] = [];
  private clickDrag: boolean[] = [];
  private onelineclick: number[]=[];


  

  public screenWidth: any;

  public screenHeight: any;

  public ratioCanvas: number=1;


ngOnInit(): void {

  // this.screenWidth = window.innerWidth;
  // // console.log("width",this.screenWidth )
  // this.screenHeight = window.innerHeight;
  // console.log("screenHeight",this.screenHeight )
  // var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
  // console.log("width",Inkcanvas.width)
  // console.log("heigth",Inkcanvas.height)
  // this.ratiowidth =1500/((90*this.screenWidth)/100)
  
  // this.ratioHeight = this.ratiowidth
  this.ChangeSizeCanvas();

}

  ngAfterContentInit(){

    var canvas=<HTMLCanvasElement> document.getElementById('myCanvas');
    var  ctx = canvas.getContext("2d");

    var QuestionCanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
    var  Qctx = QuestionCanvas.getContext("2d");

    window.addEventListener("resize", (event) => {
      this.ChangeSizeCanvas();
    });
    

    //action lors de la réception d'un strokesSubject( recepetion d'une page)
    this.subscription1 = this.StudentService.StrokesSubject.subscribe( (Strokes:any) => {

      //effacer le canvas quand on reçois une page
      this.EraseCanvas();

      //récuperer id nouvelle page
      var img = this.StudentService.cours.imageSlide;

      //trouver si quizz déja existant
      let quizz = this.StudentService.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.StudentService.cours.idImage)
      console.log('typeof',typeof quizz)
      if(typeof quizz !=='undefined'){
        this.StudentService.quizz_slide=quizz;
      }

      //récuperer liste des réponses des question
      var response_list=this.StudentService.cours.response_list;
      var response_Strokes= this.StudentService.cours.response_Strokes;

      //affichage de l'image
      if(img.width > img.height ){
        var height=(1000*img.height)/img.width;
        var width=1000;
      }else if(img.width < img.height){
        var height=1000;
        var width=(1000*img.width)/img.height;
      }else{
        var height=1000;
        var width=1000;
      }
      var middleCanvas= (canvas.width/2)-(width/2*this.ratioCanvas)
      this.AddImageOnloadcanvas(img,middleCanvas ,Strokes,this.ratioCanvas);
      this.AddImagecanvas(img,middleCanvas ,width ,height,Strokes );

      //affichage des bullet point si question
      if(typeof quizz !=='undefined' && this.StudentService.quizz_slide.quizz_list.length!=0 ){
         this.AddQuestion(this.StudentService.quizz_slide.quizz_list);
      }
      if(response_list.length!=0){
        this.AddResponseBullet(response_list)
      }
      if(response_Strokes.length!=0){
        console.log(response_Strokes)
        this.AddResponseStrokes(response_Strokes);
      }
      this.drawsaveink();

    });


    //action lors du click sur un bullet point de réponse
    QuestionCanvas.addEventListener("click",function (evt)
    {
      var mousePos = getMousePos(QuestionCanvas, evt);
      var quizz_list:Quizz[];
      let quizzSlide = mousePos.stud.cours.quizz_Slide_list.find(x => x.UIDQuizz ==mousePos.stud.cours.idImage);

      if(quizzSlide){
        quizz_list = quizzSlide?.quizz_list
      }else{
        quizz_list=[];
      }
      console.log(quizz_list)
      if(quizz_list.length == 0 ) return;
      if(mousePos.stud.quizz_slide.sumited==true) return;

      quizz_list.forEach(element => {
        console.log(element.Bullet.x, mousePos.x*mousePos.ratioCanvas)
        console.log(element.Bullet.y, mousePos.y*mousePos.ratioCanvas)
        if(element.Bullet.x<= mousePos.x/mousePos.ratioCanvas && mousePos.x/mousePos.ratioCanvas<element.Bullet.x+30 && element.Bullet.y< mousePos.y/mousePos.ratioCanvas && mousePos.y/mousePos.ratioCanvas<element.Bullet.y+30){
          const Qctx = QuestionCanvas.getContext("2d");
          switch(element.Type) {
            case "QCM": {
              if(element.Bullet.Checked==false){
                if(Qctx){
                  Qctx.beginPath();
                  Qctx.strokeStyle = "rgb(0,0,0)"
                  Qctx.fillStyle = "rgb(0, 0, 0)";
                  Qctx.lineWidth = 3
                  Qctx.fillRect((element.Bullet.x+5+5)*mousePos.ratioCanvas, (element.Bullet.y+5)*mousePos.ratioCanvas, 10*mousePos.ratioCanvas,10*mousePos.ratioCanvas);
                  Qctx.closePath();
                }
                element.Bullet.Checked=true;
              }else{
                if(Qctx){
                  Qctx.beginPath();
                  Qctx.fillStyle = "rgb(255, 255, 255)";
                  Qctx.strokeStyle = "rgb(255, 255, 255)"
                  Qctx.fillRect((element.Bullet.x+5+2)*mousePos.ratioCanvas, (element.Bullet.y+3)*mousePos.ratioCanvas, 14*mousePos.ratioCanvas,14*mousePos.ratioCanvas);
                  Qctx.closePath();
                }
                element.Bullet.Checked=false;
              }
              break;
            }
            case "QCU":{
              quizz_list.forEach(question => {
                if(Qctx){
                  Qctx.beginPath();
                  Qctx.fillStyle = "rgb(255, 255, 255)";
                  Qctx.arc((question.Bullet.x+15)*mousePos.ratioCanvas, (question.Bullet.y+15)*mousePos.ratioCanvas, 6*mousePos.ratioCanvas, 0 ,2*Math.PI,true);
                  Qctx.fill();
                  Qctx.closePath();
                }
                question.Bullet.Checked=false;
              });
              if(element.Bullet.Checked==false){
                if(Qctx){
                  Qctx.beginPath();
                  Qctx.fillStyle = "rgb(0, 0, 0)";
                  Qctx.arc((element.Bullet.x+15)*mousePos.ratioCanvas, (element.Bullet.y+15)*mousePos.ratioCanvas, 5*mousePos.ratioCanvas, 0 ,2*Math.PI,true);
                  Qctx.fill();
                  Qctx.closePath();
                }
                element.Bullet.Checked=true;
              break;
              }
            }
          }
        }
      });
      //
    }, false);

    const getMousePos = (canvas:any, evt:any) => {
      var rect = canvas.getBoundingClientRect();

      return {
          x: evt.clientX - rect.left,
          y: evt.clientY - rect.top,
          stud: this.StudentService,
          ratioCanvas: this.ratioCanvas,
      };};

  }

  EraseCanvas(){
    // this.clearCanvas();

    var canvas=<HTMLCanvasElement> document.getElementById('myCanvas');

    const ctx = canvas.getContext("2d");
    if(ctx){
      ctx.beginPath();
      ctx.clearRect(0,0,canvas.width,canvas.height);
      // ctx.globalCompositeOperation ="xor"
      // ctx.drawImage(this.oldImage,(canvas.width/2)-(this.oldwith/2),150,this.oldwith,this.oldheight);
      ctx.closePath();
    }

    var QuestionCanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
    var  Qctx = QuestionCanvas.getContext("2d");
    if(Qctx){
      Qctx.beginPath();
      Qctx.clearRect(0,0,QuestionCanvas.width,QuestionCanvas.height);
      // ctx.globalCompositeOperation ="xor"
      // ctx.drawImage(this.oldImage,(canvas.width/2)-(this.oldwith/2),150,this.oldwith,this.oldheight);
      Qctx.closePath();
    }

  }

  EraseInk(){
    var QuestionCanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
    var  Qctx = QuestionCanvas.getContext("2d");
    if(Qctx){
      Qctx.beginPath();
      Qctx.clearRect(0,0,QuestionCanvas.width,QuestionCanvas.height);
      // ctx.globalCompositeOperation ="xor"
      // ctx.drawImage(this.oldImage,(canvas.width/2)-(this.oldwith/2),150,this.oldwith,this.oldheight);
      Qctx.closePath();
    }
  }

  AddImageOnloadcanvas(img:HTMLImageElement,middleCanvas:any , Strokes:any, ratioCanvas:any){
    var canvas=<HTMLCanvasElement> document.getElementById('myCanvas');

    const ctx = canvas.getContext("2d");
    if(ctx){

      img.onload= function(){

        if(img.width > img.height ){
          var height=(1000*img.height)/img.width;
          var width=1000;
        }else if(img.width < img.height){
          var height=1000;
          var width=(1000*img.width)/img.height;
        }else{
          var height=1000;
          var width=1000;
        }
        var middleCanvas= (canvas.width/2)-(width/2*ratioCanvas)
        ctx.beginPath();
        ctx.globalCompositeOperation ="copy"
        
        ctx.drawImage(img,middleCanvas,150*ratioCanvas,width*ratioCanvas,height*ratioCanvas);
        ctx.globalCompositeOperation ="source-over"

        Strokes.forEach((element: { point: string | any[]; offset: string[]; R: string; G: string; B: string; A: string; WidthBrush: number; }) => {
          if(element.point.length==4){
            for(let i=0;i<=element.point.length-1 ;i=i+3){
              ctx.beginPath();
              ctx.moveTo((parseFloat(element.point[i])+parseFloat(element.offset[0]))*ratioCanvas,(parseFloat(element.point[i+1])+parseFloat(element.offset[1]))*ratioCanvas);


              ctx.strokeStyle = "rgba("+element.R+","+element.G+","+element.B+","+element.A+")";
              ctx.lineWidth = element.WidthBrush*ratioCanvas;
              ctx.lineCap = "round";
              ctx.lineJoin = "round";
              ctx.lineTo((parseFloat(element.point[i])+parseFloat(element.offset[0]))*ratioCanvas, (parseFloat(element.point[i+1])+parseFloat(element.offset[1]))*ratioCanvas);
              ctx.stroke();

            }

          }else{
            for(let i=0;i<=element.point.length-4 ;i=i+3){

              ctx.beginPath();
              ctx.moveTo((parseFloat(element.point[i])+parseFloat(element.offset[0]))*ratioCanvas, (parseFloat(element.point[i+1])+parseFloat(element.offset[1]))*ratioCanvas);



              ctx.strokeStyle = "rgba("+element.R+","+element.G+","+element.B+","+element.A;
              ctx.lineWidth = element.WidthBrush*ratioCanvas;
              ctx.lineCap = "round";
              ctx.lineJoin = "round";
              ctx.lineTo((parseFloat(element.point[i+3])+parseFloat(element.offset[0]))*ratioCanvas, (parseFloat(element.point[i+4])+parseFloat(element.offset[1]))*ratioCanvas);
              ctx.stroke();
            }
          }
        });
        ctx.beginPath();
      ctx.globalCompositeOperation ="source-over"
      }


      ctx.closePath();

    }

  }

  AddImagecanvas(img:HTMLImageElement,middleCanvas:any ,width:any ,height:any, Strokes:any ){
    var canvas=<HTMLCanvasElement> document.getElementById('myCanvas');

    const ctx = canvas.getContext("2d");
    if(ctx){
      ctx.beginPath();
      ctx.globalCompositeOperation ="copy"
      ctx.drawImage(img,middleCanvas,150*this.ratioCanvas,width*this.ratioCanvas,height*this.ratioCanvas);

      ctx.globalCompositeOperation ="source-over"

      Strokes.forEach((element: { point: string | any[]; offset: string[]; R: string; G: string; B: string; A: string; WidthBrush: number; }) => {
        if(element.point.length==4){
          for(let i=0;i<=element.point.length-1 ;i=i+3){
            ctx.beginPath();
            ctx.moveTo((parseFloat(element.point[i])+parseFloat(element.offset[0]))*this.ratioCanvas, (parseFloat(element.point[i+1])+parseFloat(element.offset[1]))*this.ratioCanvas);


            ctx.strokeStyle = "rgba("+element.R+","+element.G+","+element.B+","+element.A;
            ctx.lineWidth = element.WidthBrush*this.ratioCanvas;
            ctx.lineCap = "round";
            ctx.lineJoin = "round";
            ctx.lineTo((parseFloat(element.point[i])+parseFloat(element.offset[0]))*this.ratioCanvas, (parseFloat(element.point[i+1])+parseFloat(element.offset[1]))*this.ratioCanvas);
            ctx.stroke();

          }
        }else{
          for(let i=0;i<=element.point.length-4 ;i=i+3){

            ctx.beginPath();
            ctx.moveTo((parseFloat(element.point[i])+parseFloat(element.offset[0]))*this.ratioCanvas, (parseFloat(element.point[i+1])+parseFloat(element.offset[1]))*this.ratioCanvas);



            ctx.strokeStyle = "rgba("+element.R+","+element.G+","+element.B+","+element.A;
            ctx.lineWidth = element.WidthBrush*this.ratioCanvas;
            ctx.lineCap = "round";
            ctx.lineJoin = "round";
            ctx.lineTo((parseFloat(element.point[i+3])+parseFloat(element.offset[0]))*this.ratioCanvas, (parseFloat(element.point[i+4])+parseFloat(element.offset[1]))*this.ratioCanvas);
            ctx.stroke();
          }
        }
      });
      ctx.closePath();
    }
  }

  AddQuestion(quizz_list:any){
    var canvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
    const ctx = canvas.getContext("2d");

    if(ctx){
      ctx.beginPath();
      ctx.globalCompositeOperation ="source-over"
      quizz_list.forEach((element: { Type: any; Bullet: { Checked:boolean; x: number; y: number; Offset: string; }; }) => {
        switch(element.Type) {
          case "QCM": {
            ctx.beginPath();
            ctx.strokeStyle = "rgb(0,0,0)"
            ctx.fillStyle = "rgb(255, 255, 255)";
            ctx.lineWidth = 3*this.ratioCanvas
            ctx.fillRect((element.Bullet.x+5)*this.ratioCanvas, element.Bullet.y*this.ratioCanvas, 20*this.ratioCanvas, 20*this.ratioCanvas);
            ctx.rect((element.Bullet.x+5)*this.ratioCanvas, (element.Bullet.y)*this.ratioCanvas, 20*this.ratioCanvas, 20*this.ratioCanvas);
            ctx.stroke();
            ctx.lineWidth = 1*this.ratioCanvas
            var font= 20*this.ratioCanvas
            ctx.font = font.toString()+"px arial";
            ctx.fillStyle = "rgb(0, 0, 0)";
            ctx.fillText(element.Bullet.Offset, (element.Bullet.x+30)*this.ratioCanvas, (element.Bullet.y+20)*this.ratioCanvas);
            ctx.closePath();
            if(element.Bullet.Checked==true){
              ctx.beginPath();
              ctx.strokeStyle = "rgb(0,0,0)"
              ctx.fillStyle = "rgb(0, 0, 0)";
              ctx.lineWidth = 3*this.ratioCanvas
              ctx.fillRect((element.Bullet.x+5+5)*this.ratioCanvas, (element.Bullet.y+5)*this.ratioCanvas, 10*this.ratioCanvas,10*this.ratioCanvas);
              ctx.closePath();
            }

            break;
          }
          case "QCU":{
            ctx.beginPath();
            ctx.fillStyle = "rgb(255, 255, 255)";
            ctx.arc((element.Bullet.x+15)*this.ratioCanvas, (element.Bullet.y+15)*this.ratioCanvas, 8*this.ratioCanvas, 0 ,2*Math.PI,true);
            ctx.fill();

            ctx.strokeStyle = "rgb(0,0,0)"
            ctx.lineWidth = 3*this.ratioCanvas
            ctx.arc((element.Bullet.x+15)*this.ratioCanvas, (element.Bullet.y+15)*this.ratioCanvas, 8*this.ratioCanvas, 0 ,2*Math.PI,true);
            ctx.stroke();

            ctx.lineWidth = 1*this.ratioCanvas
            var font= 20*this.ratioCanvas
            ctx.font = font.toString()+"px arial";
            ctx.fillStyle = "rgb(0, 0, 0)";
            ctx.fillText(element.Bullet.Offset, (element.Bullet.x+30)*this.ratioCanvas, (element.Bullet.y+20)*this.ratioCanvas);
            ctx.closePath();

            if(element.Bullet.Checked==true){
              ctx.beginPath();
              ctx.fillStyle = "rgb(0, 0, 0)";
              ctx.arc((element.Bullet.x+15)*this.ratioCanvas, (element.Bullet.y+15)*this.ratioCanvas, 5*this.ratioCanvas, 0 ,2*Math.PI,true);
              ctx.fill();
              ctx.closePath();
            }
            break;

          }
          default:{
            break;
          }
        }
      });
    }

  }

  AddResponseStrokes(response_Strokes:any){

    var canvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
    const ctx = canvas.getContext("2d");
    if(ctx){
      response_Strokes.forEach((element: { point: string | any[]; offset: string[]; R: string; G: string; B: string; A: string; WidthBrush: number; }) => {
        if(element.point.length==4){
          for(let i=0;i<=element.point.length-1 ;i=i+3){
            ctx.beginPath();
            ctx.moveTo((parseFloat(element.point[i])+parseFloat(element.offset[0]))*this.ratioCanvas, (parseFloat(element.point[i+1])+parseFloat(element.offset[1]))*this.ratioCanvas);
            ctx.strokeStyle = "rgba("+element.R+","+element.G+","+element.B+","+element.A;
            ctx.lineWidth = element.WidthBrush*this.ratioCanvas;
            ctx.lineCap = "round";
            ctx.lineJoin = "round";
            ctx.lineTo(parseFloat((element.point[i])+parseFloat(element.offset[0]))*this.ratioCanvas, (parseFloat(element.point[i+1])+parseFloat(element.offset[1]))*this.ratioCanvas);
            ctx.stroke();

          }
        }else{
          for(let i=0;i<=element.point.length-4 ;i=i+3){

            ctx.beginPath();
            ctx.moveTo((parseFloat(element.point[i])+parseFloat(element.offset[0]))*this.ratioCanvas, (parseFloat(element.point[i+1])+parseFloat(element.offset[1]))*this.ratioCanvas);



            ctx.strokeStyle = "rgba("+element.R+","+element.G+","+element.B+","+element.A;
            ctx.lineWidth = element.WidthBrush*this.ratioCanvas;
            ctx.lineCap = "round";
            ctx.lineJoin = "round";
            ctx.lineTo((parseFloat(element.point[i+3])+parseFloat(element.offset[0]))*this.ratioCanvas, (parseFloat(element.point[i+4])+parseFloat(element.offset[1]))*this.ratioCanvas);
            ctx.stroke();
          }
        }
      });
      ctx.closePath();
    }
  }
  AddResponseBullet(response_list:any){
    var canvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
    const ctx = canvas.getContext("2d");
    if(ctx){
      ctx.beginPath();
      ctx.globalCompositeOperation ="source-over"
      response_list.forEach((element: { Type: any; Bullet: { Checked:boolean; x: number; y: number; Offset: string; }; }) => {
        switch(element.Type) {
          case "QCM": {
            if(element.Bullet.Checked){
              ctx.beginPath();
              ctx.strokeStyle = "rgb(50,205,50)"
              ctx.lineWidth = 3*this.ratioCanvas
              ctx.rect((element.Bullet.x+5)*this.ratioCanvas, element.Bullet.y*this.ratioCanvas, 20*this.ratioCanvas, 20*this.ratioCanvas);
              ctx.stroke();
              ctx.closePath();
            }
            break;
          }
          case "QCU":{
            if(element.Bullet.Checked){
              ctx.strokeStyle = "rgb(50,205,50)"
              ctx.lineWidth = 3*this.ratioCanvas
              ctx.arc((element.Bullet.x+15)*this.ratioCanvas, (element.Bullet.y+15)*this.ratioCanvas, 8*this.ratioCanvas, 0 ,2*Math.PI,true);
              ctx.stroke();
              ctx.closePath();
            }
            break;

          }
          default:{
            break;
          }
        }
      });
    }
  }
  public FindMin(linestroke:number[]):any{
    var actualMix=linestroke[0];
    var actualMiy=linestroke[1];
    var line:number[] = [];

    for(var i=0;i<linestroke.length;i=i+3){
      if(actualMix>linestroke[i]){
        actualMix=linestroke[i];
      }
      if(actualMiy>linestroke[i+1]){
        actualMiy=linestroke[i+1];
      }
    }
    for(var i=0;i<linestroke.length;i=i+3){
      line[i]=linestroke[i]-actualMix
      line[i+1]=linestroke[i+1]-actualMiy
      line[i+2]=0.5
    }
    var tmpoffset=actualMix+','+actualMiy
    console.log(tmpoffset)

    return {tmpoffset,line}
  }

  OnClickResponse(){
    this.RemoveUserGommeEvents();
    this.RemoveUserEvents();
    let quizzSlide = this.StudentService.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.StudentService.cours.idImage);
    if(quizzSlide){
      console.log(this.StudentService.quizz_slide)
      quizzSlide.sumited=true
    }
    const btn = document.getElementById('quizzFieldSet') as HTMLButtonElement | null;

    var sendstrokes:responseStroke[]=[];

    var tmpchange: { tmpoffset: string; line: string; };
      quizzSlide?.resStroke.forEach((line) =>{
        var StringStrokes:string='';
        tmpchange=this.FindMin(line);
        console.log("line",line)
        StringStrokes+= tmpchange.line+',';
        console.log("tmpchange",tmpchange)
        var tmp= new responseStroke();

        tmp.Points=StringStrokes;
        tmp.Offset=tmpchange.tmpoffset;
        tmp.DrawingAttributes={
          $type:'Camia.Layers.Components.Strokes.DA.DrawingAttributes, CamiaModels',
          Brush:{$type:'Camia.Layers.Components.Strokes.DA.Brushes, CamiaModels', Name:'Ellipse'},
          Color:{$type:'Camia.Layers.Components.Strokes.DA.Color, CamiaModels',A:255,B:0,G:0,R:0},
          Height:2,
          IsHighlighter:false,
          Width:2
        } ;
        tmp.PropertyData={$type: 'System.Collections.Generic.Dictionary`2[[System.Guid, mscorlib],[System.Object, mscorlib]], mscorlib',['dec73a1c-e094-4c42-a73d-e895ae9d5350']:'UidStrokeIdentification'}
        sendstrokes.push(tmp);


      })


    if(this.StudentService.quizz_slide.mode=='0'){

      this.StudentService.sendResponse0();
    }
    else if(this.StudentService.quizz_slide.mode=='1'){
      this.StudentService.sendResponse1();
    }
    else if(this.StudentService.quizz_slide.mode=='2'){
      this.StudentService.sendResponse2(sendstrokes);
    }
    else if(this.StudentService.quizz_slide.mode=='3'){
      this.StudentService.sendResponse3(sendstrokes);

    }


  }

public changeStyle(){

}


  private createUserEvents() {

    var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
    const ctx = Inkcanvas.getContext("2d");

    
    Inkcanvas.addEventListener("mousedown", this.pressEventHandler);
    Inkcanvas.addEventListener("mousemove", this.dragEventHandler);
    Inkcanvas.addEventListener("mouseup", this.releaseEventHandler);
    Inkcanvas.addEventListener("mouseout", this.cancelEventHandler);

    Inkcanvas.addEventListener("touchstart", this.pressEventHandler);
    Inkcanvas.addEventListener("touchmove", this.dragEventHandler);
    Inkcanvas.addEventListener("touchend", this.releaseEventHandler);
    Inkcanvas.addEventListener("touchcancel", this.cancelEventHandler);

    Inkcanvas.addEventListener("touchstart", this.pressSlideEventHandler);

    Inkcanvas.addEventListener("touchend", this.releaseSlideEventHandler);
  

}

private createUserGommeEvents(){
  var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
  const ctx = Inkcanvas.getContext("2d");


  Inkcanvas.addEventListener("mousedown", this.pressEventGommeHandler);
  Inkcanvas.addEventListener("mousemove", this.dragEventGommeHandler);
  Inkcanvas.addEventListener("mouseup", this.releaseEventGommeHandler);
  Inkcanvas.addEventListener("mouseout", this.cancelEventGommeHandler);

  Inkcanvas.addEventListener("touchstart", this.pressEventGommeHandler);
  Inkcanvas.addEventListener("touchmove", this.dragEventGommeHandler);
  Inkcanvas.addEventListener("touchend", this.releaseEventGommeHandler);
  Inkcanvas.addEventListener("touchcancel", this.cancelEventGommeHandler);

  Inkcanvas.addEventListener("touchstart", this.pressSlideGommeEventHandler);

  Inkcanvas.addEventListener("touchend", this.releaseSlideGommeEventHandler);


}

public RemoveUserEvents() {

  var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
  const ctx = Inkcanvas.getContext("2d");


  Inkcanvas.removeEventListener("mousedown", this.pressEventHandler);
  Inkcanvas.removeEventListener("mousemove", this.dragEventHandler);
  Inkcanvas.removeEventListener("mouseup", this.releaseEventHandler);
  Inkcanvas.removeEventListener("mouseout", this.cancelEventHandler);

  Inkcanvas.removeEventListener("touchstart", this.pressEventHandler);
  Inkcanvas.removeEventListener("touchmove", this.dragEventHandler);
  Inkcanvas.removeEventListener("touchend", this.releaseEventHandler);
  Inkcanvas.removeEventListener("touchcancel", this.cancelEventHandler);

  Inkcanvas.removeEventListener("touchstart", this.pressSlideEventHandler);
 
  Inkcanvas.removeEventListener("touchend", this.releaseSlideEventHandler);
  

}

private RemoveUserGommeEvents() {

  var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
  const ctx = Inkcanvas.getContext("2d");


  Inkcanvas.removeEventListener("mousedown", this.pressEventGommeHandler);
  Inkcanvas.removeEventListener("mousemove", this.dragEventGommeHandler);
  Inkcanvas.removeEventListener("mouseup", this.releaseEventGommeHandler);
  Inkcanvas.removeEventListener("mouseout", this.cancelEventGommeHandler);

  Inkcanvas.removeEventListener("touchstart", this.pressEventGommeHandler);
  Inkcanvas.removeEventListener("touchmove", this.dragEventGommeHandler);
  Inkcanvas.removeEventListener("touchend", this.releaseEventGommeHandler);
  Inkcanvas.removeEventListener("touchcancel", this.cancelEventGommeHandler);


  Inkcanvas.removeEventListener("touchstart", this.pressSlideGommeEventHandler);
 
  Inkcanvas.removeEventListener("touchend", this.releaseSlideGommeEventHandler);

}

drawsaveink(){

    let quizzSlide = this.StudentService.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.StudentService.cours.idImage);
    if(quizzSlide){
      quizzSlide.resStroke.forEach(element => {
        var offset= quizzSlide
        for(var i=0;i<element.length;i=i+3){

          var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
          var inkctx = Inkcanvas.getContext("2d",);

          if (inkctx != null){
            inkctx.lineCap = 'round';
            inkctx.lineJoin = 'round';
            inkctx.strokeStyle = 'black';
            inkctx.lineWidth = 1.5*this.ratioCanvas;
            inkctx.beginPath();

            if (element[i] && i) {
              inkctx.moveTo(element[i - 3]*this.ratioCanvas, element[i - 2]*this.ratioCanvas);
            } else {
              inkctx.moveTo((element[i] - 1)*this.ratioCanvas, element[i+1]*this.ratioCanvas);
            }

            inkctx.lineTo(element[i]*this.ratioCanvas, element[i+1]*this.ratioCanvas);
            inkctx.stroke();
          }

        }
      });

  }
}

private redraw() {
  var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
  var inkctx = Inkcanvas.getContext("2d",);
  if (inkctx != null){

    inkctx.lineCap = 'round';
    inkctx.lineJoin = 'round';
    inkctx.strokeStyle = 'black';
    inkctx.lineWidth = 1*this.ratioCanvas;

    let clickX = this.clickX;
    
    let clickDrag = this.clickDrag;
    let clickY = this.clickY;
    for (let i = 0; i < clickX.length; ++i) {
      inkctx.beginPath();
        if (clickDrag[i] && i) {
          inkctx.moveTo(clickX[i - 1]*this.ratioCanvas, clickY[i - 1]*this.ratioCanvas);
        } else {
          inkctx.moveTo((clickX[i] - 1)*this.ratioCanvas, clickY[i]*this.ratioCanvas);
        }

        inkctx.lineTo(clickX[i]*this.ratioCanvas, clickY[i]*this.ratioCanvas);
        inkctx.stroke();
    }
    inkctx.closePath();
  }
}

private addClick(x: any, y: any, dragging: boolean) {
  console.log (x)
  this.clickX.push(x);
  this.clickY.push(y);
  this.clickDrag.push(dragging);

  this.onelineclick.push(x,y,0.5)
  console.log (x)
}

private clearCanvas() {
  var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
  var inkctx = Inkcanvas.getContext("2d",);
  if (inkctx != null){
    inkctx.clearRect(0, 0, Inkcanvas.width, Inkcanvas.height);
    this.clickX = [];
    this.clickY = [];
    this.clickDrag = [];
  }
  console.log('response',this.StudentService.cours.response_list);
}

private pressSlideEventHandler = (e:TouchEvent) =>{

  if(e.touches.length==2){
    var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
    Inkcanvas.removeEventListener("mousedown", this.pressEventHandler);
    Inkcanvas.removeEventListener("mousemove", this.dragEventHandler);
    Inkcanvas.removeEventListener("mouseup", this.releaseEventHandler);
    Inkcanvas.removeEventListener("mouseout", this.cancelEventHandler);

    Inkcanvas.removeEventListener("touchstart", this.pressEventHandler);
    Inkcanvas.removeEventListener("touchmove", this.dragEventHandler);
    Inkcanvas.removeEventListener("touchend", this.releaseEventHandler);
    Inkcanvas.removeEventListener("touchcancel", this.cancelEventHandler);
    this.StudentService.paint = false;
  }

}

private releaseSlideEventHandler = (e:TouchEvent) =>{

  if(this.StudentService.paint == false){
    var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
    Inkcanvas.addEventListener("mousedown", this.pressEventHandler);
    Inkcanvas.addEventListener("mousemove", this.dragEventHandler);
    Inkcanvas.addEventListener("mouseup", this.releaseEventHandler);
    Inkcanvas.addEventListener("mouseout", this.cancelEventHandler);

    Inkcanvas.addEventListener("touchstart", this.pressEventHandler);
    Inkcanvas.addEventListener("touchmove", this.dragEventHandler);
    Inkcanvas.addEventListener("touchend", this.releaseEventHandler);
    Inkcanvas.addEventListener("touchcancel", this.cancelEventHandler);
    this.StudentService.paint = true;
  }

}

private pressSlideGommeEventHandler = (e:TouchEvent) =>{

  if(e.touches.length==2){
    var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
    Inkcanvas.removeEventListener("mousedown", this.pressEventGommeHandler);
    Inkcanvas.removeEventListener("mousemove", this.dragEventGommeHandler);
    Inkcanvas.removeEventListener("mouseup", this.releaseEventGommeHandler);
    Inkcanvas.removeEventListener("mouseout", this.cancelEventGommeHandler);

    Inkcanvas.removeEventListener("touchstart", this.pressEventGommeHandler);
    Inkcanvas.removeEventListener("touchmove", this.dragEventGommeHandler);
    Inkcanvas.removeEventListener("touchend", this.releaseEventGommeHandler);
    Inkcanvas.removeEventListener("touchcancel", this.cancelEventGommeHandler);
    this.StudentService.gomme = false;
  }

}

private releaseSlideGommeEventHandler = (e:TouchEvent) =>{

  if(this.StudentService.gomme == false){
    var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
    Inkcanvas.addEventListener("mousedown", this.pressEventGommeHandler);
    Inkcanvas.addEventListener("mousemove", this.dragEventGommeHandler);
    Inkcanvas.addEventListener("mouseup", this.releaseEventGommeHandler);
    Inkcanvas.addEventListener("mouseout", this.cancelEventGommeHandler);

    Inkcanvas.addEventListener("touchstart", this.pressEventGommeHandler);
    Inkcanvas.addEventListener("touchmove", this.dragEventGommeHandler);
    Inkcanvas.addEventListener("touchend", this.releaseEventGommeHandler);
    Inkcanvas.addEventListener("touchcancel", this.cancelEventGommeHandler);
    this.StudentService.gomme = true;
  }

}

private pressEventHandler = (e: MouseEvent | TouchEvent) => {
  console.log("ici ca paint")
  var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
  let mouseX = (e as TouchEvent).changedTouches ?
               (e as TouchEvent).changedTouches[0].pageX :
               (e as MouseEvent).pageX;
  let mouseY = (e as TouchEvent).changedTouches ?
               (e as TouchEvent).changedTouches[0].pageY :
               (e as MouseEvent).pageY;
  mouseX -= Inkcanvas.offsetLeft;
  mouseY -= Inkcanvas.offsetTop+200;

  this.StudentService.paint = true;
  console.log(mouseX);
  this.addClick(mouseX/this.ratioCanvas, mouseY/this.ratioCanvas, false);
  this.redraw();
}

private dragEventHandler = (e: MouseEvent | TouchEvent) => {
  var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
  let mouseX = (e as TouchEvent).changedTouches ?
               (e as TouchEvent).changedTouches[0].pageX :
               (e as MouseEvent).pageX;
  let mouseY = (e as TouchEvent).changedTouches ?
               (e as TouchEvent).changedTouches[0].pageY :
               (e as MouseEvent).pageY;
  mouseX -= Inkcanvas.offsetLeft;
  mouseY -= Inkcanvas.offsetTop+200;

  if (this.StudentService.paint) {
    var x=mouseX/this.ratioCanvas 
    var y=mouseY/this.ratioCanvas 
    console.log(y+"yyy")
      this.addClick(x, y, true);
      this.redraw();
  }

  e.preventDefault();
}

private pressEventGommeHandler= (e: MouseEvent | TouchEvent) => {
  var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
  let mouseX = (e as TouchEvent).changedTouches ?
               (e as TouchEvent).changedTouches[0].pageX :
               (e as MouseEvent).pageX;
  let mouseY = (e as TouchEvent).changedTouches ?
               (e as TouchEvent).changedTouches[0].pageY :
               (e as MouseEvent).pageY;
  mouseX -= Inkcanvas.offsetLeft;
  mouseY -= Inkcanvas.offsetTop+200;
  this.StudentService.gomme = true

  let quizzSlide = this.StudentService.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.StudentService.cours.idImage);
    
  var supr:boolean = false;
  var indexSupr!:number
  
  if( quizzSlide ){
    let copyStrokes=[...quizzSlide.resStroke]
    for(var  i=0;i<copyStrokes.length;i++){

      for(var y=0;y<copyStrokes[i].length;y=y+3){
        
        if(copyStrokes[i][y]<(mouseX/this.ratioCanvas)+(10*this.ratioCanvas) && copyStrokes[i][y]>(mouseX/this.ratioCanvas)-(10*this.ratioCanvas) && copyStrokes[i][y+1]<(mouseY/this.ratioCanvas)+(10*this.ratioCanvas) && copyStrokes[i][y+1]>(mouseY/this.ratioCanvas)-(10*this.ratioCanvas)){

          supr=true
          indexSupr=i;
          
        }
      }
     
    }
    if(supr==true){
      delete copyStrokes[indexSupr]
      quizzSlide.resStroke=[]
      copyStrokes.forEach(element => {
        if(element){
          console.log(element);
          quizzSlide?.resStroke.push(element)
        }
      });
      this.EraseInk();
      this.drawsaveink() 

      
      supr=false
    }
  }
  }

  // var inkctx = Inkcanvas.getContext("2d",);
  // if (inkctx != null){
  //   inkctx.beginPath();
  //   inkctx.clearRect(mouseX*this.ratiowidth-10,mouseY*this.ratioHeight-10,10,10);
  //   inkctx.closePath();
  // }
  


private dragEventGommeHandler= (e: MouseEvent | TouchEvent) => {
  
  var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
  let mouseX = (e as TouchEvent).changedTouches ?
               (e as TouchEvent).changedTouches[0].pageX :
               (e as MouseEvent).pageX;
  let mouseY = (e as TouchEvent).changedTouches ?
               (e as TouchEvent).changedTouches[0].pageY :
               (e as MouseEvent).pageY;
  mouseX -= Inkcanvas.offsetLeft;
  mouseY -= Inkcanvas.offsetTop+200;
  if(this.StudentService.gomme==true){
    let quizzSlide = this.StudentService.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.StudentService.cours.idImage);
    
    var supr:boolean = false;
    var indexSupr!:number

    if( quizzSlide ){
      let copyStrokes=[...quizzSlide.resStroke]
      for(var  i=0;i<copyStrokes.length;i++){

        for(var y=0;y<copyStrokes[i].length;y=y+3){
          if(copyStrokes[i][y]<(mouseX/this.ratioCanvas)+(10*this.ratioCanvas) && copyStrokes[i][y]>(mouseX/this.ratioCanvas)-(10*this.ratioCanvas) && copyStrokes[i][y+1]<(mouseY/this.ratioCanvas)+(10*this.ratioCanvas) && copyStrokes[i][y+1]>(mouseY/this.ratioCanvas)-(10*this.ratioCanvas)){
            console.log("test",copyStrokes[i][y],(mouseX*this.ratioCanvas),copyStrokes[i][y+1],(mouseY*this.ratioCanvas))
            console.log(copyStrokes)

            supr=true
            indexSupr=i;
            
          }
        }
       
      }
      if(supr==true){
        delete copyStrokes[indexSupr]
        quizzSlide.resStroke=[]
        copyStrokes.forEach(element => {
          if(element){
            console.log(element);
            quizzSlide?.resStroke.push(element)
          }
        });
        this.EraseInk();
        this.drawsaveink() 

        
        supr=false
      }
    }
  }
  e.preventDefault();
}

private releaseEventGommeHandler = () => {
  this.StudentService.gomme= false;
}
private cancelEventGommeHandler = () => {
  this.StudentService.gomme= false;
}

public clearEventHandler = () => {
  this.clearCanvas();
}

private releaseEventHandler = () => {

  this.StudentService.paint = false;
  this.redraw();
  let quizzSlide = this.StudentService.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.StudentService.cours.idImage);
  quizzSlide?.resStroke.push(this.onelineclick);
  this.onelineclick=[]
  this.clickX = [];
  this.clickY = [];
  this.clickDrag = [];

}

private cancelEventHandler = () => {
  this.StudentService.paint = false;
}

public Onclickpen(btn:any,btn1:any) {
  console.log("clickPen");

  this.StudentService.buttonpen = !this.StudentService.buttonpen; // update selected state
  console.log(this.StudentService.buttonpen);
  if (this.StudentService.buttonpen == true) {
    this.redraw();
    this.createUserEvents();
    this.RemoveUserGommeEvents();
    btn.setAttribute("class","btntoolOn");
    btn1.setAttribute("class","btntoolOff");
    this.StudentService.buttongomme = false
  } else {
    btn.setAttribute("class","btntoolOff");
    this.RemoveUserEvents();
  }
}

public OnclickGomme(btn:any,btn1:any){
  this.StudentService.buttongomme = !this.StudentService.buttongomme; // update selected state

  if (this.StudentService.buttongomme == true) {
    this.RemoveUserEvents();
    this.createUserGommeEvents();
   btn?.setAttribute("class","btntoolOn");
   btn1?.setAttribute("class","btntoolOff");
   this.StudentService.buttonpen = false
  } else {
    btn?.setAttribute("class","btntoolOff");
    this.RemoveUserGommeEvents();
  }
}
public Onclickpoubelle() {

  this.EraseInk();
  this.clickX= [];
  this.clickY= [];
  this.clickDrag= [];
  this.onelineclick=[];
  let quizzSlide = this.StudentService.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.StudentService.cours.idImage);
  if(quizzSlide){
    quizzSlide.resStroke=[]
  }
}

public ChangeSizeCanvas(){
  var Inkcanvas=<HTMLCanvasElement> document.getElementById('myQuestionCanvas');
  this.screenWidth = window.innerWidth;
  this.screenHeight = window.innerHeight;
  this.ratioCanvas = 1
  Inkcanvas.style.width= 1500+"px"
  Inkcanvas.style.height= 4242+"px"
  
  Inkcanvas.width= 1500*this.ratioCanvas
  Inkcanvas.height= 4242*this.ratioCanvas

  var imageCanvas=<HTMLCanvasElement> document.getElementById('myCanvas');
  imageCanvas.style.width= 1500+"px"
  imageCanvas.style.height= 4242+"px"
  imageCanvas.width= 1500*this.ratioCanvas
  imageCanvas.height= 4242*this.ratioCanvas
  
  // var canvasdiv=<HTMLDivElement> document.getElementById('canvasdiv');

  this.StudentService.refresh()
  // console.log(imageCanvas.width, imageCanvas.height, this.ratioCanvas)

}

}


