import { Component } from '@angular/core';
import { WebSocketService } from '../service/web-socket.service';
import { StudentService } from '../service/student.service';
import { CanvasSlideComponent } from '../canvas-slide/canvas-slide.component';
import { Subscription } from 'rxjs';
import { quizzSlide } from '../models/quizzSlide.model';
import { Router } from '@angular/router';

@Component({
  providers:[CanvasSlideComponent ],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {

  constructor(public WebSocketService: WebSocketService, public StudentService: StudentService,private comp: CanvasSlideComponent,public router:Router){}
  inscriptionAlow!: boolean;
  public upbutton: boolean=false;
  public downButton:boolean=false;

  ngAfterContentInit(){

    this.StudentService.buttonReset.subscribe((val:any)=>{
      if(val==true){
        this.Onclickpen();
      }else{
        this.OnclickGomme();
      }
      

    });
  }

  OnClickDeconection(){
      this.WebSocketService.socket.disconnect();
      this.WebSocketService._isConnect=false;

      var Menu=document.getElementById("menu")
      if(Menu){
        if ( Menu.getAttribute( 'aria-expanded' ) == 'true' ) {
          Menu.click();
        }
      }
  }

  //ferme le menu déroulent quand on click sur un item dedans
  clickButtonInMenu(){
    var Menu=document.getElementById("menu")
    if(Menu){
      if ( Menu.getAttribute( 'aria-expanded' ) == 'true' ) {
        Menu.click();
      }
    }
  }


  Onclickpen(){
    var btn=document.getElementById("pen")
    var btn1=document.getElementById("gomme")
    this.comp.Onclickpen(btn,btn1);
  }
  

  OnclickGomme(){
    var btn=document.getElementById("gomme")
    var btn1=document.getElementById("pen")
    this.comp.OnclickGomme(btn,btn1);
  }


  changeStyle() {
    if(this.StudentService.quizz_slide){
      const btn = document.getElementById('quizzFieldSet') as HTMLButtonElement | null;
      if(this.StudentService.quizz_slide.sumited == true){
        this.comp.RemoveUserEvents()
        this.StudentService.buttonpen = false;
        return {
          'background': 'grey'
          };
      }else{
  
        return{
          'background': 'white'
        };
  
      }
    }else{
      return{}
    }
  }

  changeStyleUp(){
    if(this.StudentService.cours.List_ID_slide.indexOf(this.StudentService.cours.idslide)==0){
      this.upbutton=true;
      return {
        'background': 'grey'

        };
    }else{
      this.upbutton=false;
      return{
        'background': 'white'

      };

    }

  }

  changeStyleDown(){
    if(this.StudentService.cours.List_ID_slide.indexOf(this.StudentService.cours.idslide)==this.StudentService.cours.List_ID_slide.length-1){
      this.downButton=true;
      return {
        'background': 'grey'

        };
    }else{
      this.downButton=false;
      return{
        'background': 'white'

      };

    }

  }

  OnClickResponse() {
    this.comp.OnClickResponse();
  }


  Onclickpoubelle() {
    this.comp.Onclickpoubelle();
  }

  OnclickDown() {
    this.StudentService.syncro=false;
    console.log("OnclickDown")
    var index =this.StudentService.cours.List_ID_slide.indexOf(this.StudentService.cours.idImage.toString())
    console.log(index)
    console.log(this.StudentService.cours.idImage)
    this.WebSocketService.emit('ask_page',this.StudentService.cours.List_ID_slide[index+1] );
  }
  OnclickHome() {
    this.StudentService.syncro=true;
    this.StudentService.eventToList()
    }
  OnclickUp() {
    this.StudentService.syncro=false;
    console.log("OnclickUp")
    var index =this.StudentService.cours.List_ID_slide.indexOf(this.StudentService.cours.idImage.toString())
    console.log(index)
    console.log(this.StudentService.cours.idImage)
    this.WebSocketService.emit('ask_page',this.StudentService.cours.List_ID_slide[index-1] );
    }
}


