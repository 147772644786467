<header class="header">
    <nav class="navbar navbar-expand-lg fixed-top py-3 position-fixed ">

        <div class="container container_style">

            <!-- log de l'application -->

            <a routerLink="" class="navbar-brand text-uppercase font-weight-bold">
                <img src="../../assets/logo kassis blanc.png" alt="logo IntuiNote" class="img-navbar">
                IntuiNote
            </a>


            <!-- bouton outil dans l'application  -->

            <div *ngIf=" router.url=='/Cours'" class="navbar-toggler navbar-toggler-right" style="display: block; margin-left: auto; margin-right: auto; text-align: center;">
                <div *ngIf=" this.StudentService.quizz_slide.mode !='0'&& this.StudentService.quizz_slide.mode !='1'" >
                    <div *ngIf=" !this.StudentService.quizz_slide.sumited || this.StudentService.quizz_slide.mode !='2'&& this.StudentService.quizz_slide.mode !='3' ">
                        <button id="pen"  (click)="this.Onclickpen()"     type="button"   class="btntoolOff"> 
                            <img src="../../assets/crayon.png" class="img-button">
                        </button>
        
                        <button id="gomme"  (click)="this.OnclickGomme()"   type="button" class="btntoolOff" >
                            <img src="../../assets/icons8-gomme-96.png" class="img-button">
                        </button>
        
                        <button id="poubelle"  (click)="this.Onclickpoubelle()"   type="button" class="btntoolOff">
                            <img src="../../assets/poubelle-de-recyclage.png" class="img-button">
                        </button>
        
                    </div>
                </div>
               
                <div *ngIf="StudentService.cours.responseActive" class="navbar-toggler navbar-toggler-right" style="display: block; margin-left: auto; margin-right: auto; text-align: center;">
                    <button id="quizzFieldSet" class="button-sumited" (click)="this.OnClickResponse()"  [disabled]="this.StudentService.quizz_slide.sumited" [ngStyle]="this.changeStyle()" type="button"  class="btntoolOff">
                        <img src="../../assets/icons8-envoyer-96.png" class="img-button">
                    </button>
                </div>

                <div>
                    <button id="up"  type="button" (click)="this.OnclickUp()" [ngStyle]="this.changeStyleUp()" [disabled]="upbutton" class="btntoolOff"> 
                        <img src="../../assets/fleche-gauche.png" class="img-button">
                    </button>
    
                    <button id="syncro"  type="button" (click)="this.OnclickHome()" class="btntoolOff" >
                        <img src="../../assets/home.png" class="img-button">
                    </button>
    
                    <button id="down"  type="button" (click)="this.OnclickDown()" [ngStyle]="this.changeStyleDown()" [disabled]="downButton" class="btntoolOff">
                        <img src="../../assets/fleche-droite.png" class="img-button">
                    </button>

                </div>
            </div>



            <!-- bouton de changement de menu -->
            
            <button id="menu" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler navbar-toggler-right">
                <i class="fa fa-bars navbar-img"></i>
            </button>
           
            <div id="navbarSupportedContent" class="collapse navbar-collapse">
                <ul class="navbar-nav ml-auto">
                    <li *ngIf="WebSocketService._isConnect" class="nav-item"><a routerLink="/Classe" (click)="clickButtonInMenu()" class="nav-link text-uppercase font-weight-bold">Groupes </a></li>
                    <li *ngIf="WebSocketService._isConnect" class="nav-item"><a routerLink="/Cours" (click)="clickButtonInMenu()" class="nav-link text-uppercase font-weight-bold">Cours </a></li>
                    <li *ngIf="WebSocketService._isConnect" class="nav-item"><a (click)="OnClickDeconection()" (click)="clickButtonInMenu()"routerLink="/Connexion" class="nav-link text-uppercase font-weight-bold">Déconnexion</a></li>
                    <li *ngIf="!WebSocketService._isConnect" class="nav-item"><a routerLink="/Connexion" (click)="clickButtonInMenu()" class="nav-link text-uppercase font-weight-bold">Connexion </a></li>
                    <li *ngIf="inscriptionAlow" class="nav-item"><a routerLink="/Inscription" (click)="clickButtonInMenu()" class="nav-link text-uppercase font-weight-bold">Inscription</a></li>
                    <li class="nav-item"><a routerLink="/About"(click)="clickButtonInMenu()" class="nav-link text-uppercase font-weight-bold">À propos</a></li>
                </ul>
            </div>
        </div>
    </nav>
    
</header>