import { Component,Input } from '@angular/core';
import { Group } from '../models/group.model';

import { Router } from '@angular/router';
import { StudentService } from '../service/student.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})

export class GroupComponent {

constructor(private StudentService: StudentService, private router: Router){}

@Input() group!:Group;

  Onclick() {
    //todo :voir pour sortir du cours quand on reviens a la page de groupe et non quand on click sur un nouveau groupe
    if(this.StudentService.cours.title){
      this.StudentService.leave();
    }
      this.StudentService.join(this.group);
      
      this.router.navigate(['/Cours']);
  }
}
