import { Component } from '@angular/core';
import { Group } from '../models/group.model';
import { AvailableGroups } from '../available-groups/available-groups.component';
import { StudentService } from '../service/student.service';
import { Router } from '@angular/router';
import { WebSocketService } from '../service/web-socket.service';


@Component({
  selector: 'app-connexion-page',
  templateUrl: './connexion-page.component.html',
  styleUrls: ['./connexion-page.component.scss']
})
export class ConnexionPageComponent {

  constructor(public StudentService: StudentService){}

  userEmail!: String;
  userPassword!: String;

  
  group!: Group

  onSubmitForm(): void{ 
     this.StudentService.connect(this.userEmail,this.userPassword);
  }
}


  

