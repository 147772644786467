<!-- <p>canvas-slide works!</p> -->
<div id="global" class="container main" >
    <div class="pt-5 text-white" >
        <div class="pt-5 text-white move-top" style=" margin-top: 200px; display: block; margin-left: auto; margin-right: auto;" >
            <div id="canvasdiv" style="display: grid;margin-left: auto; margin-right: auto;" >

                    <canvas id="myCanvas" style="grid-row: 1; grid-column: 1;border: 2px solid black;margin-left: auto; margin-right: auto; background: white; z-index: -1;" ></canvas>
                    <canvas id="myQuestionCanvas" style="grid-row: 1; grid-column: 1; border: 2px solid black;margin-left: auto; margin-right: auto;background: transparent ; z-index: 1;"  ></canvas>
                
            </div>
        </div>
    </div>
</div>