import { Component,Input } from '@angular/core';
import { Quizz } from '../models/quizz.model';
import { WebSocketService } from '../service/web-socket.service';


@Component({
  selector: 'app-quizz-bullet',
  templateUrl: './quizz-bullet.component.html',
  styleUrls: ['./quizz-bullet.component.scss']
})
export class QuizzBulletComponent {

  @Input() quizz!:Quizz;
  constructor(private WebSocketService: WebSocketService){}

  OnClick(){
    // if(this.quizz.Type=='QCM'){
    //   if(this.quizz.Bullet.Checked==false)
    //     this.quizz.Bullet.Checked=true;
    //   else
    //     this.quizz.Bullet.Checked=false;
      
    // }else if(this.quizz.Type=='QCU'){
    //   this.WebSocketService.quizz_list.forEach(element => {
    //     element.Bullet.Checked=false;
    //   });
    //   this.quizz.Bullet.Checked=true;
    // }
  }

}
