<main>
    <div class="container">
        <div class="pt-5 text-white">
            <header class="py-5 mt-5">
                <h1 class="display-4">Connexion</h1>
            </header>
            <form (ngSubmit)="onSubmitForm()">
                <div>
                    <label for="email">Votre e-mail</label>
                    <input type="email" id="email" name="userEmail" placeholder="monadresse@mail.com" required [(ngModel)]="userEmail" >
                </div>
                <div>
                    
                    <label for="password">Mot de passe</label>
                    <input type="password" id="password" name="userPassword" placeholder="Entrer le mot de passe"  required [(ngModel)]="userPassword" >
                </div>
                <div >
                    <button type="submit" >Connexion</button>
                    <div>
                        <p>{{StudentService.information}}</p>
                    </div>
                </div>

            </form>
        </div>
    </div>
</main>