export class Stroke {
    constructor(
                //information couleur des traieet taille
                public A:any,
                public R:any,
                public G:any,
                public B:any,
                public WidthBrush:any,
                public IsHighlighter:boolean,
                //information sur la position 
                public offset:any,
                public point:any,
                
                ) {
    }
  }