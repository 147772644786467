import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AboutPageComponent } from './about-page/about-page.component';
import { InscriptionPageComponent } from './inscription-page/inscription-page.component';
import { ConnexionPageComponent } from './connexion-page/connexion-page.component';
import { FormsModule } from '@angular/forms';
import { AvailableGroups } from './available-groups/available-groups.component';
//import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { WebSocketService } from './service/web-socket.service';
import { GroupComponent } from './group/group.component';
import { CoursComponent } from './cours/cours.component';
import { QuizzBulletComponent } from './quizz-bullet/quizz-bullet.component';
import { CanvasSlideComponent } from './canvas-slide/canvas-slide.component';

//const config: SocketIoConfig = {url: 'http://localhost:443/', options:{}};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomePageComponent,
    AboutPageComponent,
    InscriptionPageComponent,
    ConnexionPageComponent,
    AvailableGroups,
    GroupComponent,
    CoursComponent,
    QuizzBulletComponent,
    CanvasSlideComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    //SocketIoModule.forRoot(config),
  ],
  providers: [WebSocketService],
  bootstrap: [AppComponent]
})
export class AppModule { }
