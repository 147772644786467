import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { WebSocketService } from './web-socket.service';

export const authguardGuard: CanActivateFn = (route:ActivatedRouteSnapshot, state:RouterStateSnapshot):   
Observable<boolean | UrlTree> 
| Promise<boolean | UrlTree> 
| boolean 
| UrlTree=> {

  return inject(WebSocketService).authenticated() ? true : inject(Router).createUrlTree(['\Connexion']);



  
};
