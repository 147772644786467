<main>
    <div class="container">
        <div class="pt-5 text-white">
            <header class="py-5 mt-5">
                <h1 class="display-4">Inscription</h1>
            </header>
            <form>
                <div>
                    <label for="nom">Votre Nom</label>
                    <input type="text" id="inputNameUser" name="nom" placeholder="Smith" required>
                </div>
                <div>
                    <label for="email">Votre e-mail</label>
                    <input type="email" id="inputMailUser" name="email" placeholder="monadresse@mail.com" required>
                </div>
                <div>
                    
                    <label for="password">Mot de passe</label>
                    <input type="password" id="inputPassUser" name="password" placeholder="Entrer le mot de passe"  required>
                </div>
                <div>
                    <button id="btnsubmit2">Valider</button>
                </div>
            </form>
        </div>
    </div>
</main>