import { Injectable } from '@angular/core';
import { WebSocketService } from '../service/web-socket.service';
import { Router } from '@angular/router';

import { Environment } from 'src/environments/environment';

import { Group } from '../models/group.model';
import { Quizz } from '../models/quizz.model';
import { ConnexionPageComponent } from '../connexion-page/connexion-page.component';
import { CanvasSlideComponent } from '../canvas-slide/canvas-slide.component';
import { Cours } from '../models/cours.model';
import { Stroke } from '../models/stroke.model';
import { Subject, Subscription, empty, isEmpty } from 'rxjs';
import { quizzSlide } from '../models/quizzSlide.model';
import { responseStroke } from '../models/responseStroke.model';
import { HeaderComponent } from '../header/header.component';

@Injectable({
  providedIn: 'root'
})

export class StudentService {

    constructor(public WebSocketService: WebSocketService, private router:Router){}


//liste des différents cours qui comprend l'auteur le titre et l'ID du cours
    grp_list:Group[]=[];
//variable d'affichage d'information d'erreur utilisateur
    information!:string;

    cours:Cours = new Cours();
    
    public StrokesSubject = new Subject<any>(); 
    public buttonReset = new Subject<any>(); 
    public QuizzSubject = new Subject<any>(); 
    public oldId:string="";

    //new variable 
    public paint: boolean = false;
    public gomme: boolean = false;
    public buttonpen:boolean = false;
    public buttongomme:boolean=false;
    public quizz_slide:quizzSlide=new quizzSlide("","","",false);
    public syncro:boolean=true;

    user!:any;
    firstName?:any;

    connect(userEmail:String ,userPassword:String){


        this.WebSocketService.tryconnect();
        this.WebSocketService.listen('connect').subscribe((data: any) => {
            console.log('connect');
            console.log(data);
            this.WebSocketService.emit('authentication',{username: userEmail, password: userPassword, New: false });
          
            this.WebSocketService.listen('authenticated').subscribe((dataauth: any) => {

                console.log('authenticated');
                console.log(dataauth);
      
      
             
                this.WebSocketService.listen('info').subscribe((datainfo: any) => {
                    this.WebSocketService._isConnect = dataauth;
                    this.router.navigate(['/Classe']);

                    console.log("info");
                    console.log(datainfo);
                    this.user=datainfo;
                    this.firstName=datainfo.firstname

                    this.WebSocketService.listen('available-groups').subscribe((dataGroup: any) => {
                    this.grp_list=[];

                        console.log("available-groups");
                        console.log(dataGroup);
                        dataGroup.forEach((element: { lessonName: string; author: string; id: string; }) => {
                            this.grp_list.push(new Group(element.lessonName,element.author,element.id))
            
                        });
                    });

                    //pour le moment inutile;
                    this.WebSocketService.listen('pages').subscribe((datapages: any) => {
                        console.log("pages");    
                        console.log(datapages);
                        
                        
                        var SlideIdTemps:string[]=[];
                        var SlideVersionTemps:string[]=[];
                        var List_ID_slide=this.cours.List_ID_slide
                        var List_version_slide=this.cours.List_version_slide
                        
                        datapages.data.forEach((element: { id: string; version: string; index:number}) => {
                            SlideIdTemps[element.index]=element.id
                            SlideVersionTemps[element.index]=element.version
                            
                        });

                        console.log(SlideIdTemps );

                        console.log(SlideIdTemps.length,List_ID_slide.length);
                        
                        if(this.cours.List_ID_slide.length == 0){

                            this.cours.List_ID_slide=SlideIdTemps;
                            this.cours.List_version_slide=SlideVersionTemps;
                            console.log("list a jour");
                            
                        } else if(SlideIdTemps.length != List_ID_slide.length ){
                            
                            
                            var findDivergence = function (a1:any, a2:any) {
                                var result:string[] = [], longerLength = a1.length >= a2.length ? a1.length : a2.length;
                                for (i = 0; i < longerLength; i++){
                                    if (a1[i] !== a2[i]) {
                                        return i;
                                    }
                                }
                                return false;
                            };
                            var diff=findDivergence(SlideIdTemps,List_ID_slide) 
                            if(typeof diff == 'number')
                                if(SlideIdTemps[diff]!= null){
                             let diff1 = SlideIdTemps.filter(val => !List_ID_slide.indexOf(val))
                            this.cours.List_ID_slide=[]
                            this.cours.List_ID_slide=SlideIdTemps;
                            this.cours.List_version_slide=SlideVersionTemps;
                            
                            console.log("this.cours.List_ID_slide ");
                            console.log(this.cours.List_ID_slide );
                            if(diff1.length==1){
                                this.WebSocketService.emit('ask_page', diff1);
                                
                            }
                                }
                            

                            

                        }else{
                            let diff2 = SlideVersionTemps.filter(val => !List_version_slide.includes(val))
                            
                            for(var i=0;i<List_version_slide.length;i++){
                                if(List_version_slide[i]!= SlideVersionTemps[i]){
                                    console.log("diff2 ");
                                    console.log(List_version_slide[i]);
                                    console.log(SlideVersionTemps[i]);
                                    this.eventToList();
                                    this.cours.List_version_slide=SlideVersionTemps;
                                    break;
                                }
                            }
                            this.cours.List_ID_slide=[]
                            this.cours.List_ID_slide=SlideIdTemps;
                            this.cours.List_version_slide=SlideVersionTemps;
                        }
                        console.log(  this.cours.List_ID_slide)

                            
                    });

                    this.WebSocketService.listen('page').subscribe((datapage: any) => {
                        
                        if(datapage!=null){
                            console.log("page");    
                            console.log(datapage);
                            this.cours.Strokes=[];

                             //verifier si le quizz est actif
                             console.log("Is Active ?")
                             console.log(datapage.data.Active)
                             if(datapage.data.Active==true){
                                 this.cours.responseActive=true
                             }else{
                                 this.cours.responseActive=false 
                             }

                            //récuperer l'image avec verification si null (datapage.data.ImageRelativePath)
                            this.cours.idslide=datapage.data.ImageRelativePath;
                            this.cours.idImage=datapage.data.UID;
                            
                            console.log(this.cours.idslide,this.cours.idImage)


                            //verification que si le prof a changé de slide
                            if( this.cours.idImage != this.oldId){
                                this.cours.response_list=[];
                                this.cours.response_Strokes=[];
                            }

                            
                            
                           
                            // verification si page blanche et image du cours accessible 

                            if(this.cours.idslide==null){
                                this.cours.imageSlide = new Image();
                            }else{

                                var http= new XMLHttpRequest();
                                http.open('HEAD', Environment.SOCKET_ENDPOINT+Environment.API_URL_PREFIX+this.cours.idslide, false);
                                http.send();
                                console.log("http " + http.status)
                                if(http.status == 500){
                                    this.cours.imageSlide = new Image();
                                }else{
                                    this.cours.imageSlide.src= Environment.SOCKET_ENDPOINT+Environment.API_URL_PREFIX+this.cours.idslide;
                                }
                            }

                            //récuperation des strokes 
                            if(datapage.data.Layer.Components.length!=0){
                                
                                datapage.data.Layer.Components.forEach((element: { Strokes: any[]; }) => {
                                    if(element.Strokes){
                                        element.Strokes.forEach(stroke =>{
                                            var splitPointStroke=stroke.Points.split(",")
                                            var splitOffsetStroke=stroke.Offset.split(",")
                                            this.cours.Strokes.push(new Stroke( stroke.DrawingAttributes.Color.A,
                                                                stroke.DrawingAttributes.Color.R,
                                                                stroke.DrawingAttributes.Color.G,
                                                                stroke.DrawingAttributes.Color.B,
                                                                stroke.DrawingAttributes.Width,
                                                                stroke.DrawingAttributes.IsHighlighter,
                                                                splitOffsetStroke,
                                                                splitPointStroke));
                                        })
                                    }
                                });
                                console.log("strokes")
                                console.log(this.cours.Strokes)
                                
                            }
                           
                            //récuperer le bulletquizz
                            var quizz_list:Quizz[]=[]

                            if(datapage.data.BulletComponents){
                                
                                datapage.data.BulletComponents.forEach( (element: { Type: string; Bullet: { Checked: any; Offset: any; Position: { X: any; Y: any; }; }; })  => {
                                    quizz_list.push(new Quizz( element.Type,{Checked:element.Bullet.Checked,Offset:element.Bullet.Offset,x: element.Bullet.Position.X,y: element.Bullet.Position.Y}));
                                    
                                });
                                                                
                                console.log(datapage.data.BulletComponents);
                            }

                            
                            this.oldId = this.cours.idImage;

                            //stockage des informations des slides cours
                            
                                var test =this.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.cours.idImage);
                               
                                if(typeof test !=='undefined'){
                                    
                                    test.quizz_list=quizz_list
                                    console.log("etape1")
                                    console.log(this.cours.quizz_Slide_list)
                                
                                }else{
                                    var AnswerUID=this.guidHashCode(); 
                                    this.cours.quizz_Slide_list.push(new quizzSlide(datapage.data.UID,AnswerUID,datapage.data.Mode,false,quizz_list))
                                    console.log("etape2")
                                    console.log(this.cours.quizz_Slide_list)
                                }
                      
                                if(this.buttonpen==true){
                                    this.buttonReset.next(true);
                                }
                                if(this.buttongomme==true){
                                    this.buttonReset.next(false);
                                }
                               
                            this.StrokesSubject.next(this.cours.Strokes);
                        }                      
                    });

                    this.WebSocketService.listen('message').subscribe((datamessage: any) => {
                        console.log("message");    
                        console.log(datamessage);
                    });

                    this.WebSocketService.listen('event').subscribe((dataevent: any) => {
                        
                        //problème non résolu: qui on soumet une deuxième fois la question et qu'on déjà submit le fieldset est disable 
                        console.log('event ' + dataevent.type)
                        console.log(dataevent);
                        

                        if(dataevent.type == 'new-page-index'){
                            
                            this.cours.idSlideProf=dataevent.data.SlideID;

                        }else if( dataevent.type== 'quiz-ask'){
                        
                            var quizzinfo =this.cours.quizz_Slide_list.find(x => x.UIDQuizz == dataevent.data.UID);

                            if(quizzinfo){

                               
                                quizzinfo.sumited=false;
                                quizzinfo.resStroke=[];
                            }

                            this.cours.response_list=[];
                            this.cours.response_Strokes=[];

                            this.cours.idSlideProf=dataevent.data.UID;
                           
                            
                        }else if( dataevent.type=='quiz-correct-answer'){

                            if(dataevent.data.BulletComponents!=null){
                                dataevent.data.BulletComponents.forEach( (element: { Type: string; Bullet: { Correct: any; Offset: any; Position: { X: any; Y: any; }; }; })  => {
                                    this.cours.response_list.push(new Quizz( element.Type,{Checked:element.Bullet.Correct,Offset:element.Bullet.Offset,x: element.Bullet.Position.X,y: element.Bullet.Position.Y}));
                                    });
                            }

                            if(dataevent.data.Layer.Components){
                                dataevent.data.Layer.Components.forEach((element: { Strokes: any[]; }) => {
                                    if(element.Strokes){
                                        element.Strokes.forEach(stroke =>{
                                            var splitPointStroke=stroke.Points.split(",")
                                            var splitOffsetStroke=stroke.Offset.split(",")
                                            this.cours.response_Strokes.push(new Stroke( stroke.DrawingAttributes.Color.A,
                                                                stroke.DrawingAttributes.Color.R,
                                                                stroke.DrawingAttributes.Color.G,
                                                                stroke.DrawingAttributes.Color.B,
                                                                stroke.DrawingAttributes.Width,
                                                                stroke.DrawingAttributes.IsHighlighter,
                                                                splitOffsetStroke,
                                                                splitPointStroke));
                                        })

                                    }
                                });
                            }
                        }
                        if(this.syncro==true){
                        this.WebSocketService.emit('ask_page', this.cours.idSlideProf);
                        }  
                    });

                });
              
            });

            this.WebSocketService.listen('unauthorized').subscribe((data: any)=>{
              console.log('unauthorized');
              console.log(data);
              this.information="Echec de la connexion";
              
              this.WebSocketService._isConnect=false;
              
            })

            this.WebSocketService.listen('exception').subscribe((data: any)=>{
                console.log('exception');
                console.log(data);
                this.information="Echec de la connexion";
                this.WebSocketService._isConnect=false;
                this.router.navigate(['/Connexion'])
            })

          });
    }
    
    public refresh(){
        this.StrokesSubject.next(this.cours.Strokes);
    }

    leave(){
        this.WebSocketService.emit('leaveRoom', this.cours.Id);
       
    }

    join(group :Group){

        var res = this.WebSocketService.emitWithCallback("joinGroup",group.Id);
        
        console.log("group joined "+ group.title);
        console.log(group);

        this.cours = new Cours();
        this.cours.title = group.title
        this.cours.auteur = group.auteur
        this.cours.Id = group.Id
        this.cours.quizz_Slide_list=[]

        this.askPageList();

        this.eventToList();
        
    }

    //demande toutes les pages
    askPageList(){
        this.WebSocketService.emit("ask_page_list",this.cours.Id);
        console.log("ask_page_list");
    }


    // to do : aller plus loin pour voir quelle sont les différent event to list !!!!!
    // actuellement demande la page courante
    eventToList(){
        var test = {me:false,type:'slide-index',data:{'$type': 'Camia.Sharing.AskingSlide, CamiaModels',User:this.user.mail},idList:[this.cours.auteur]}
        this.WebSocketService.emit("eventToList",test);
        console.log("eventToList")
    }

    guidHashCode () {
       return Math.floor((1 + Math.random()) * 0x10000)
    }

    sendResponse0(){
        var quizzinfo =this.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.cours.idImage);
        if(quizzinfo){
        this.WebSocketService.emit('eventToList',{
            idList:[this.cours.auteur],
            me: false,
            type: 'quiz-answer',
            data: {
              QuizMode: '0',
              AnswerUID: quizzinfo?.AnswerUID,
              UID: quizzinfo.UIDQuizz,
              Bullets: quizzinfo.quizz_list
            }
          })
        }
    }
    sendResponse1(){
        var quizzinfo =this.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.cours.idImage);
        if(quizzinfo){
        this.WebSocketService.emit('eventToList',{
            idList:[this.cours.auteur],
            me: false,
            type: 'quiz-answer',
            data: {
              QuizMode: '1',
              AnswerUID: quizzinfo?.AnswerUID,
              UID: quizzinfo.UIDQuizz,
              Bullets: quizzinfo.quizz_list
            }
          })
        }
    }
    sendResponse2(sendstrokes:responseStroke[]){
        // var quizzinfo =this.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.cours.idImage);
        // if(quizzinfo){
        // this.WebSocketService.emit('eventToList',{
        //     idList:[this.cours.auteur],
        //     me: false,
        //     type: 'quiz-answer',
        //     data: {
        //       QuizMode: '2',
        //       AnswerUID: quizzinfo?.AnswerUID,
        //       UID: quizzinfo.UIDQuizz,
        //       Strokes: sendstrokes
        //     }
        //   })
        // }
        var quizzinfo =this.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.cours.idImage);
        if(quizzinfo){
        this.WebSocketService.emit('eventToList',{
            $type:'<>f__AnonymousType1`4[[System.Boolean, mscorlib],[System.String, mscorlib],[System.Object, mscorlib],[System.Collections.Generic.List`1[[System.String, mscorlib]], mscorlib]], NetworkClient.Windows',
            me: false,
            type: 'quiz-answer',
            data: {
              $type: 'Camia.Sharing.SharedQuizAnswer, CamiaModels',
              UID: this.cours.idSlideProf,
              AnswerUID: quizzinfo?.AnswerUID,
              UserName: this.firstName,
              QuizMode: 2,
              Bullets: null,
              Strokes:sendstrokes,
              Components:[]          
              //Strokes: Stroke
            },
            idList:[this.cours.auteur]
          })
        }
    }
    sendResponse3(sendstrokes:responseStroke[]){
        
        var quizzinfo =this.cours.quizz_Slide_list.find(x => x.UIDQuizz == this.cours.idImage);
        if(quizzinfo){
        this.WebSocketService.emit('eventToList',{
            $type:'<>f__AnonymousType1`4[[System.Boolean, mscorlib],[System.String, mscorlib],[System.Object, mscorlib],[System.Collections.Generic.List`1[[System.String, mscorlib]], mscorlib]], NetworkClient.Windows',
            me: false,
            type: 'quiz-answer',
            data: {
              $type: 'Camia.Sharing.SharedQuizAnswer, CamiaModels',
              UID: this.cours.idSlideProf,
              AnswerUID: quizzinfo?.AnswerUID,
              UserName: this.firstName,
              QuizMode: 3,
              Bullets: null,
              Strokes:sendstrokes,
              Components:[]          
              //Strokes: Stroke
            },
            idList:[this.cours.auteur]
          })
        }
    }

    


    
    
}