export class responseStroke {
  Points:string='';
  Offset: string='';
  PropertyData!: {
    '$type': string,
    "dec73a1c-e094-4c42-a73d-e895ae9d5350": string;
  };
  DrawingAttributes!: {
    $type:string,
    Color:{$type:string ,A:number,B:number,G:number,R:number},
    IsHighlighter:boolean,
    Width:number,
    Height:number,
    Brush:{$type: string, Name:string} 
  }
 }