import { Component, HostListener, OnInit } from '@angular/core';
import { WebSocketService } from '../service/web-socket.service';
import { Environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Quizz } from '../models/quizz.model';
import { StudentService } from '../service/student.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-cours',
  templateUrl: './cours.component.html',
  styleUrls: ['./cours.component.scss']
})
export class CoursComponent implements OnInit {

  constructor(public StudentService: StudentService, private router: Router){}


  
  ngOnInit(): void {

  //   window.addEventListener("beforeunload", function (e) {
  //     var confirmationMessage = "\o/";
  //     console.log("cond");
  //     e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
  //     return confirmationMessage;              // Gecko, WebKit, Chrome <34
  // });
    // //demande la liste des pages du cours au serveur 
    
    // this.StudentService.askPageList();

  }
  
  @HostListener("window:beforeunload", ["$event"])
  unloadHandler(event: { preventDefault: () => void; returnValue: string; }) {
      event.preventDefault();
      return event.returnValue ;
  }

  
  // onSubmitForm(): void{ 
  //   const btn = document.getElementById('quizzFieldSet') as HTMLButtonElement | null;
  //   btn?.setAttribute('disabled', 'true');
  //   console.log('submit');
  //   this.SubmitElement=true;
  //   this.WebSocketService.emit('event',{
  //     me: false,
  //     type: 'quiz-answer',
  //     data: {
  //       QuizMode: '0',
  //       AnswerUID: this.AnswerUID,
  //       UID: this.WebSocketService.UIDQuizz,
  //       Bullets: this.WebSocketService.quizz_list
  //     }
  //   })
  // }



}
